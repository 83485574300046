@import '@fontsource/open-sans/300';
@import '@fontsource/open-sans/400';
@import '@fontsource/open-sans/500';
@import '@fontsource/open-sans/600';
@import '@fontsource/open-sans/700';
@import '@fontsource/open-sans/800';

code {
  font-family: source-code-pro, 'Open Sans', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
button {
  cursor: pointer;
}

svg {
  flex-shrink: 0;
  display: inline-block;
  transform-origin: center;
  transition: fill 0.2s, transform 0.2s;
}

img,
video {
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #000;
  -webkit-text-fill-color: #fff;
  font: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.no-click {
  pointer-events: none;
}

.invisible {
  opacity: 0;
}

#crisp-chatbox a {
  display: none !important;
}

#crisp-chatbox {
  z-index: 10000000 !important;
}

/* .crisp-client #crisp-chatbox .cc-unoo {
  transform: translate(150%);
  z-index: 10000 !important;
}
.crisp-client #crisp-chatbox {
  z-index: 1000 !important;
} */
